export default function ContactMe () {
    return (
        <section id="Contact" className="contact--section">
            <div>
                <p className="sub--title"></p>
                <h2>Contact Me</h2>
                <p className="text-lg">
                    lorem ipsum dolor, sit amet
                </p>
            </div>
            <form className="contact--form-container">
                <div className="container">
                    <labal htmlFor="first-name" className="contact--label">
                        <span className="text-md">First Name</span>
                        <input
                            type="text"
                            className="contact--input text-md"
                            name="first-name"
                            id="first-name"
                            required
                        />
                    </labal>
                    <labal htmlFor="last-name" className="contact--label">
                        <span className="text-md">Last Name</span>
                        <input
                            type="text"
                            className="contact--input text-md"
                            name="last-name"
                            id="last-name"
                            required
                        />
                    </labal>
                    <labal htmlFor="email" className="contact--label">
                        <span className="text-md">Email</span>
                        <input
                            type="email"
                            className="contact--input text-md"
                            name="email"
                            id="email"
                            required
                        />
                    </labal>
                    <labal htmlFor="phone-number" className="contact--label">
                        <span className="text-md">Phone Number</span>
                        <input
                            type="number"
                            className="contact--input text-md"
                            name="phone-number"
                            id="phone-number"
                            required
                        />
                    </labal>
                </div>
                <labal htmlFor="choose-topic" className="contact--label">
                    <span className="text-md">Choose a topic</span>
                    <select id="choose-topic" className="contact--input text-md" >
                        <option>Select One...</option>
                        <option>Item One...</option>
                        <option>Item Two...</option>
                        <option>Item Three...</option>
                    </select>
                </labal>
                <labal htmlFor="message" className="contact--label">
                    <span className="text-md">Message</span>
                    <textarea
                        className="contact--input text-md"
                        id="message"
                        rows="8"
                        placeholder="Type your message..."
                    />
                </labal>
                <label htmlFor="checkbox" className="checkbox--label">
                    <input type="checkbox" required name="checkbox" id="checkbox" />
                    <span className="text-ssm">I accept the terms</span>
                </label>
                <div>
                    <button className="btn btn-primary contact--form--btn">Submit</button>
                </div>
            </form>
        </section>
    )
}