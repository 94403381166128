export default function HeroSection() {
    return (
        <section id="heroSection" className="hero--section">
            <div className="hero--section--content--box">
                <div className="hero--section--content">
                    <p className="section--title">Hey, I'm Mac</p>
                    <h1 className="hero--section--title">
                        <spacn className="hero--section-title--color">Technology</spacn>{" "}<br />Enthusiast
                        </h1>
                        <p className="hero--section-description">
                        With over 20 years of experience in technology, starting from my high school days of running Cat5 through office buildings, I've enjoyed learning about a wide range of technologies.
                            <br /><br />
                            Throughout high school and college, I explored various technological pursuits, including being a member of a LAN Party group we humorously named Goat Pron—yes, you read that correctly. During college, I was fortunate to work for a historic railroad, where I continued to engage with technology. 
                            <br /><br />
                            Fast forward more than a decade, and I now find myself in Seattle, WA, enjoying the stunning weather of the Pacific Northwest and immersed in one of the world's technology hubs.
                            </p>
                </div>
                <button className="btn btn-primary">Get In Touch</button> 

            </div>
            <div className="hero--section--img">
                <img src="./img/heroimg.png" alt="Hero Section" />
            </div>
        </section>
        );
}