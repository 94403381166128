export default function AboutMe() {
    return (
        <section id="AboutMe" className="about--section">
            <div className="about--section--img">
                <img src="./img/about-me.png" alt="About me"></img>
            </div>
            <div className="hero--section--content--box about about--section--box">
                <div className="hero--section--content">
                    <p className="section--title">About</p>
                    <h1 className="skills-section--header">About Me</h1>
                    <p className="hero--section-descrtion">Lerm ipsom dolor sit amet</p>
                    <p className="hero--section-descrtion">Lerm ipsom dolor sit amet</p>
                    <p className="hero--section-descrtion">Lerm ipsom dolor sit amet</p>
                    <p className="hero--section-descrtion">Lerm ipsom dolor sit amet</p>
                </div>
            </div>
        </section>
    );
}